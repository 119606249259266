.App {
  text-align: center;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.navbar-nav a:hover {
  color: yellow !important;
}

.navbar-nav a.active {
  color: yellow !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@font-face {
  font-family: 'MavenPro';
  src: url("fonts/MavenPro.ttf");
  src: url("fonts/MavenPro.ttf") format('ttf'),
  url("fonts/MavenPro.ttf") format('truetype');
}

@font-face {
  font-family: 'MavenPro-Black';
  src: url("fonts/static/MavenPro-Black.ttf");
  src: url("fonts/static/MavenPro-Black.ttf") format('ttf'),
  url("fonts/static/MavenPro-Black.ttf") format('truetype');
}

@font-face {
  font-family: 'MavenPro-Bold';
  src: url("fonts/static/MavenPro-Bold.ttf");
  src: url("fonts/static/MavenPro-Bold.ttf") format('ttf'),
  url("fonts/static/MavenPro-Bold.ttf") format('truetype');
}

@font-face {
  font-family: 'MavenPro-ExtraBold';
  src: url("fonts/static/MavenPro-ExtraBold.ttf");
  src: url("fonts/static/MavenPro-ExtraBold.ttf") format('ttf'),
  url("fonts/static/MavenPro-ExtraBold.ttf") format('truetype');
}

@font-face {
  font-family: 'MavenPro-Medium';
  src: url("fonts/static/MavenPro-Medium.ttf");
  src: url("fonts/static/MavenPro-Medium.ttf") format('ttf'),
  url("fonts/static/MavenPro-Medium.ttf") format('truetype');
}

@font-face {
  font-family: 'MavenPro-Regular';
  src: url("fonts/static/MavenPro-Regular.ttf");
  src: url("fonts/static/MavenPro-Regular.ttf") format('ttf'),
  url("fonts/static/MavenPro-Regular.ttf") format('truetype');
}

@font-face {
  font-family: 'MavenPro-SemiBold';
  src: url("fonts/static/MavenPro-SemiBold.ttf");
  src: url("fonts/static/MavenPro-SemiBold.ttf") format('ttf'),
  url("fonts/static/MavenPro-SemiBold.ttf") format('truetype');
}
